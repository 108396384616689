import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline'

export const wrapRootElement = ({ element }) => (
    <>
        <CssBaseline /> 
        {element}
        <form name="contact" data-netlify="true" style={{opacity:0}}>
            <input name="name" type="text"/>
            <input name="email" type="email"/>
            <input name="email" type="email"/>
            <input name="message" type="message"/>
        </form>
    </>
  );